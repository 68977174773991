import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from '../views/Home';
import Success from '../views/Success';
import Information from '../views/Information';

const AnimatedRoutes = (props: any) => {
  const location = useLocation();
  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Information />} />
      <Route path="requestservice" element={<Home />} />
      <Route path="success" element={<Success />} />
    </Routes>
  );
};

export default AnimatedRoutes;
