import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Plan from '../components/Plan';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from '../components/ConfirmationModal';
import {
  IState,
  IBuilding,
  IPlan,
  IProperties,
  ISelectedPlan,
  IService,
  IUnit,
} from '../ts/interfaces/home_interfaces';

const Home: React.FC = () => {
  const [states, setStates] = React.useState<Array<IState>>([]);
  const [properties, setProperties] = React.useState<Array<IProperties>>([]);
  const [selectedState, setSelectedState] = React.useState<string>('');
  const [selectedProp, setSelectedProp] = React.useState<string>('');
  const [selectedBuilding, setSelectedBuilding] = React.useState<string>('');
  const [selectedUnit, setSelectedUnit] = React.useState<string>('');
  const [selectedPlan, setSelectedPlan] = React.useState<ISelectedPlan | null>(
    null
  );
  const [plans, setPlans] = React.useState<Array<IPlan> | null | undefined>([]);
  const [buildings, setBuildings] = React.useState<Array<IBuilding> | null>([]);
  const [units, setUnits] = React.useState<Array<IUnit> | null>([]);
  const [serviceRequest, setServiceRequest] = React.useState<IService>({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    plan_id: '',
    prop_id: '',
    building: '',
    unit_id: '',
    last_user: '',
  });
  const [confirmEmail, setConfirmEmail] = React.useState<string>('');
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const [modalShow, setModalShow] = React.useState<boolean>(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  const handleChange = () => {
    setSelectedPlan(null);
    setModalShow(false);
  };

  const phoneRegex: RegExp =
    /^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-/\s.]?[0-9]{4}$/;

  const emailRegex: RegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

  const navigate = useNavigate();

  const isDisabled =
    serviceRequest.building !== '' &&
    serviceRequest.email !== '' &&
    serviceRequest.firstname !== '' &&
    serviceRequest.lastname !== '' &&
    serviceRequest.phone !== '' &&
    serviceRequest.unit_id !== '' &&
    serviceRequest.email === confirmEmail &&
    phoneRegex.test(serviceRequest.phone) &&
    emailRegex.test(serviceRequest.email);

  React.useEffect(() => {
    getStates();
  }, []);

  const getStates = async () => {
    const myHeaders = new Headers();
    myHeaders.append('NetManagerKey', '9d4732ab-19c1-41f8-8407-b924de48314e');
    myHeaders.append('Content-Type', 'application/json');
    const data = await fetch(
      'https://server.mdunetech.com/api/Properties/GetStatesList',
      {
        method: 'GET',
        mode: 'cors',
        headers: myHeaders,
      }
    );
    const jsonData = await data.json();
    setStates(jsonData);
  };

  const getProperties = async (StateAbbr: string) => {
    const myHeaders = new Headers();
    myHeaders.append('NetManagerKey', '9d4732ab-19c1-41f8-8407-b924de48314e');
    myHeaders.append('Content-Type', 'application/json');
    if (StateAbbr !== '') {
      const data = await fetch(
        `https://server.mdunetech.com/api/Properties/GetPropByState/${StateAbbr}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: myHeaders,
        }
      );
      // const jsonData = await data.json();
      // setProperties(jsonData);
      const jsonData = await data.json();
      setProperties(jsonData);
    }
  };

  const getPlans = async (prop_id: string) => {
    const myHeaders = new Headers();
    myHeaders.append('NetManagerKey', '9d4732ab-19c1-41f8-8407-b924de48314e');
    myHeaders.append('Content-Type', 'application/json');
    const data = await fetch(
      `https://server.mdunetech.com/api/Properties/GetPlansByProp/${prop_id}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: myHeaders,
      }
    );
    const jsonData = await data.json();
    setPlans(jsonData);
  };

  const getBuildings = async (prop_id: string) => {
    const myHeaders = new Headers();
    myHeaders.append('NetManagerKey', '9d4732ab-19c1-41f8-8407-b924de48314e');
    myHeaders.append('Content-Type', 'application/json');
    const data = await fetch(
      `https://server.mdunetech.com/api/Properties/GetBuldsByProp/${prop_id}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: myHeaders,
      }
    );
    const jsonData = await data.json();
    setBuildings(jsonData);
  };

  const getUnits = async (prop_id: string, build_unit: string) => {
    const myHeaders = new Headers();
    myHeaders.append('NetManagerKey', '9d4732ab-19c1-41f8-8407-b924de48314e');
    myHeaders.append('Content-Type', 'application/json');
    const data = await fetch(
      `https://server.mdunetech.com/api/Properties/GetUnitsByBuld/${prop_id}/${build_unit}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: myHeaders,
      }
    );
    const jsonData = await data.json();
    setUnits(jsonData);
  };

  const requestService = async () => {
    setModalShow(false);
    setSubmitted(true);
    const myHeaders = new Headers();
    myHeaders.append('NetManagerKey', '9d4732ab-19c1-41f8-8407-b924de48314e');
    myHeaders.append('Content-Type', 'application/json');
    const data = await fetch(
      `https://server.mdunetech.com/api/Properties/RequestService`,
      {
        method: 'POST',
        mode: 'cors',
        headers: myHeaders,
        body: JSON.stringify(serviceRequest),
      }
    );
    if (data.status === 200) {
      navigate('/success');
    }
  };

  const handleStateChange = (event: string) => {
    setBuildings(null);
    setUnits(null);
    setSelectedProp('');
    setServiceRequest({
      ...serviceRequest,
      unit_id: '',
    });
    setPlans(null);
    setSelectedUnit('');
    const obj = JSON.parse(event);
    setSelectedState(event);
    getProperties(obj.StateAbbr);
  };

  const handlePropChange = (event: string) => {
    setUnits(null);
    setSelectedBuilding('');
    const obj = JSON.parse(event);
    setSelectedProp(event);
    getPlans(obj.prop_id);
    getBuildings(obj.prop_id);
  };

  const handleBuildingChange = (event: string) => {
    setSelectedBuilding(event);
    setServiceRequest({ ...serviceRequest, building: event });
    getUnits(JSON.parse(selectedProp).prop_id, event);
  };

  const handleUnitChange = (event: string) => {
    const obj = JSON.parse(event);
    setSelectedUnit(event);
    setServiceRequest({ ...serviceRequest, unit_id: obj.unit_id });
  };

  const handlePlanSelection = (plan: any) => {
    setSelectedPlan(plan);
    setServiceRequest({
      ...serviceRequest,
      plan_id: plan.plan_id,
      prop_id: JSON.parse(selectedProp).prop_id,
    });
    setModalShow(true);
  };

  return (
    <React.Fragment>
      {submitted ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : (
        ''
      )}
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: '100%' }}
        exit={{ x: window.innerWidth }}
      >
        <div className="container p-4">
          <p className="display-5">Dedicated Fiber Optics Access Enrollment</p>
          <form>
            <Row>
              <Col lg={6}>
                <label htmlFor="state">
                  State<sup style={{ color: 'red' }}>*</sup>
                </label>
                <select
                  id="state"
                  className="form-select"
                  value={selectedState}
                  onChange={(e) => handleStateChange(e.target.value)}
                >
                  <option defaultValue={'default'}>Choose From List</option>
                  {states.map((state, i) => (
                    <option value={JSON.stringify(state)} key={i}>
                      {state.StateName}
                    </option>
                  ))}
                </select>
              </Col>
              <Col lg={6}>
                <label htmlFor="property">
                  Property<sup style={{ color: 'red' }}>*</sup>
                </label>
                <select
                  id="property"
                  className="form-select"
                  value={selectedProp}
                  onChange={(e) => handlePropChange(e.target.value)}
                >
                  <option defaultValue={'default'}>Choose From List</option>

                  {properties.map((prop, i) => (
                    <option value={JSON.stringify(prop)} key={i}>
                      {prop.prop_name}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            <Row>
              <Col lg={6} className="mt-3">
                <label htmlFor="building">
                  Building<sup style={{ color: 'red' }}>*</sup>
                </label>
                <select
                  id="building"
                  className="form-select"
                  value={selectedBuilding}
                  onChange={(e) => handleBuildingChange(e.target.value)}
                >
                  <option defaultValue={'default'}>Choose From List</option>

                  {buildings?.map((building, i) => (
                    <option value={building.unit_building} key={i}>
                      {building.unit_building}
                    </option>
                  ))}
                </select>
              </Col>
              <Col lg={6} className="mt-3">
                <label htmlFor="unit">
                  Unit<sup style={{ color: 'red' }}>*</sup>
                </label>
                <select
                  id="building"
                  className="form-select"
                  value={selectedUnit}
                  onChange={(e) => handleUnitChange(e.target.value)}
                >
                  <option defaultValue={'default'}>Choose From List</option>

                  {units?.map((unit, i) => (
                    <option value={JSON.stringify(unit)} key={i}>
                      {unit.unit_number}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            <Row>
              <Col lg={6} className="mt-3">
                <label htmlFor="firstname">
                  First name<sup style={{ color: 'red' }}>*</sup>
                </label>
                <input
                  id="firstname"
                  className="form-control"
                  value={serviceRequest?.firstname}
                  type={'text'}
                  onChange={(e) =>
                    setServiceRequest({
                      ...serviceRequest,
                      firstname: e.target.value,
                    })
                  }
                />
              </Col>
              <Col lg={6} className="mt-3">
                <label htmlFor="lastname">
                  Last name<sup style={{ color: 'red' }}>*</sup>
                </label>
                <input
                  id="lastname"
                  className="form-control"
                  value={serviceRequest?.lastname}
                  type={'text'}
                  onChange={(e) =>
                    setServiceRequest({
                      ...serviceRequest,
                      lastname: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4} className="mt-3">
                <label htmlFor="email">
                  Email address<sup style={{ color: 'red' }}>*</sup>
                </label>
                <input
                  id="email"
                  className="form-control"
                  value={serviceRequest?.email}
                  type={'email'}
                  onChange={(e) =>
                    setServiceRequest({
                      ...serviceRequest,
                      email: e.target.value,
                      last_user: e.target.value,
                    })
                  }
                />
                {serviceRequest.email !== '' &&
                !emailRegex.test(serviceRequest.email) ? (
                  <small className="w-100" style={{ color: 'red' }}>
                    Please enter a valid email address
                  </small>
                ) : (
                  ''
                )}
              </Col>
              <Col lg={4} className="mt-3">
                <label htmlFor="confirm-email">
                  Confirm email address<sup style={{ color: 'red' }}>*</sup>
                </label>
                <input
                  id="confirm-email"
                  className="form-control"
                  value={confirmEmail}
                  type={'email'}
                  onChange={(e) => setConfirmEmail(e.target.value)}
                />
              </Col>
              <Col lg={4} className="mt-3">
                <label htmlFor="phone">
                  Phone number<sup style={{ color: 'red' }}>*</sup>
                </label>
                <input
                  id="phone"
                  className="form-control"
                  value={serviceRequest?.phone}
                  onChange={(e) =>
                    setServiceRequest({
                      ...serviceRequest,
                      phone: e.target.value,
                    })
                  }
                />
                {serviceRequest.phone !== '' &&
                !phoneRegex.test(serviceRequest.phone) ? (
                  <small className="w-100" style={{ color: 'red' }}>
                    Please enter a valid phone number
                  </small>
                ) : (
                  ''
                )}
              </Col>
            </Row>
          </form>

          {plans && isDisabled ? (
            <motion.div
              className="mt-3"
              initial={{ y: '25%', opacity: 0, scale: 0.5 }}
              animate={{ y: 0, opacity: 1, scale: 1 }}
              transition={{ duration: 0.2, ease: 'easeInOut' }}
              exit={{ x: '25%', opacity: 0, scale: 0.5 }}
            >
              {selectedPlan !== null ? (
                <motion.div
                  initial={{ x: '-50%', opacity: 0, scale: 0.5 }}
                  animate={{ x: 0, opacity: 1, scale: 1 }}
                  transition={{ duration: 0.2, ease: 'easeInOut' }}
                  exit={{ x: '-50%', opacity: 0, scale: 0.5 }}
                >
                  {/* <Row>
                    <Col className="mx-auto" lg={3}>
                      <div className="plan-item">
                        <section
                          className="text-center"
                          style={{ padding: '10px', background: '#F7F7F7' }}
                        >
                          <h3>{selectedPlan.plan_name}</h3>
                        </section>
                        <div className="plan-content">
                          <section className="text-center">
                            <h5 style={{ color: '#009C00' }}>
                              ${selectedPlan.price_base}
                            </h5>

                            <button
                              className="plan-btn mt-1"
                              onClick={requestService}
                              // onClick={() => console.log(serviceRequest)}
                              disabled={submitted}
                            >
                              Confirm Plan
                            </button>
                            <hr />
                          </section>
                          <section className="text-center">
                            <div className=" mt-3">
                              <span>
                                <b>Download Speed:&nbsp;</b>
                              </span>
                              {selectedPlan.DownloadSpeed} Mbps
                            </div>
                            <div className="mt-3">
                              <span>
                                <b>Upload Speed:&nbsp;</b>
                              </span>
                              {selectedPlan.UploadSpeed} Mbps
                            </div>
                          </section>
                          <section className="text-center mt-3">
                            <button
                              className="cancel-btn mt-1"
                              onClick={() => setSelectedPlan(null)}
                            >
                              Change Selection
                            </button>
                          </section>
                        </div>
                      </div>
                    </Col>
                  </Row> */}
                </motion.div>
              ) : (
                <div className="plan-wrapper">
                  {plans?.map((plan) => (
                    <Plan
                      key={plan.plan_id}
                      DownloadSpeed={plan.DownloadSpeed}
                      UploadSpeed={plan.UploadSpeed}
                      available={false}
                      bill_type={plan.bill_type}
                      plan_description={plan.plan_description}
                      plan_id={plan.plan_id}
                      plan_name={plan.plan_name}
                      plan_notes={plan.plan_notes}
                      price_base={plan.price_base}
                      price_tax_fee={plan.price_tax_fee}
                      prop_id={plan.prop_id}
                      isDisabled={!isDisabled}
                      onClick={() => handlePlanSelection(plan)}
                    />
                  ))}
                </div>
              )}
            </motion.div>
          ) : (
            ''
          )}
          <ConfirmationModal
            show={modalShow}
            info={serviceRequest}
            state={selectedState ? JSON.parse(selectedState) : undefined}
            prop={selectedProp ? JSON.parse(selectedProp) : undefined}
            unit={selectedUnit ? JSON.parse(selectedUnit) : undefined}
            plan={selectedPlan}
            handleClose={handleClose}
            handleRequest={requestService}
            disabled={!isDisabled}
            handleChange={handleChange}
          />
        </div>
      </motion.div>
    </React.Fragment>
  );
};

export default Home;
