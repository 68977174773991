import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { ISelectedPlan, IService } from '../ts/interfaces/home_interfaces';

interface Props {
  show: boolean;
  info: IService;
  state?: { StateName: string; StateAbbr: string };
  prop?: { prop_id: string; prop_name: string };
  unit?: { unit_number: string };
  plan?: ISelectedPlan | null;
  handleClose: () => void;
  handleRequest: () => void;
  disabled: boolean;
  handleChange: () => void;
}

const ConfirmationModal = (props: Props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.handleChange}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Review Your Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="text-center">
          <Col>
            <label>First Name</label>
            <p className="fs-4">{props.info.firstname}</p>
          </Col>
          <Col>
            <label>Last Name</label>
            <p className="fs-4">{props.info.lastname}</p>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <label>Email</label>
            <p className="fs-4">{props.info.email}</p>
          </Col>
          <Col>
            <label>Phone</label>
            <p className="fs-4">{props.info.phone}</p>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <label>State</label>
            <p className="fs-4">{props.state?.StateName}</p>
          </Col>
          <Col>
            <label>Property</label>
            <p className="fs-4">{props.prop?.prop_name}</p>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <label>Building</label>
            <p className="fs-4">{props.info.building}</p>
          </Col>
          <Col>
            <label>Unit</label>
            <p className="fs-4">{props.unit?.unit_number}</p>
          </Col>
        </Row>
        <div className="plan-item">
          <div className="plan-content">
            <section className="text-center">
              <h3>Selected Plan: {props.plan?.plan_name}</h3>
              <h5 style={{ color: '#009C00' }}>
                $
                {(
                  (props.plan?.price_base ? props.plan?.price_base : 0) +
                  (props.plan?.price_tax_fee ? props.plan?.price_tax_fee : 0)
                ).toFixed(2)}
              </h5>
            </section>
            <section className="text-center">
              <div className=" mt-3">
                <span>
                  <b>Download Speed:&nbsp;</b>
                </span>
                {props.plan?.DownloadSpeed} Mbps
              </div>
              <div className="mt-3">
                <span>
                  <b>Upload Speed:&nbsp;</b>
                </span>
                {props.plan?.UploadSpeed} Mbps
              </div>
            </section>
          </div>
        </div>
        <p className="text-center fw-light">
          A MDU Network Technologies representative will contact you after your
          enrollment has been processed.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={props.handleChange}>
          Change Plan
        </Button>
        <button
          className="plan-btn"
          onClick={props.handleRequest}
          disabled={props.disabled}
        >
          Submit Enrollment Request
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
