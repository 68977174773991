import React from 'react';
import { Row } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const Information: React.FC = () => {
  const navigate = useNavigate();

  const handleForward = () => {
    navigate('/requestservice');
  };

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: '100%' }}
      exit={{ x: window.innerWidth }}
    >
      <div className="container mt-3 mb-3">
        <h1 className=" ">Important information when requesting service</h1>
        <div>
          <h4>Enrollment Procedure:</h4>
          <p className="lead">
            To establish Internet service in your apartment please follow these
            instructions: <br />
            <ul>
              <li>
                Please fill out the enrollment form found on the next page.
              </li>
            </ul>
            Our team will create your account:
            <ul>
              <li>
                Within two hours (M, T, W, Th, F 8:30 AM to 4:30 PM Eastern), or
              </li>
              <li>
                First thing the next business morning (weekdays after hours,
                Sat, Sun, holidays).
              </li>
            </ul>
            Moving in over the weekend? No problem! Simply fill out the
            enrollment form and call ahead of time so that your account will be
            created, and waiting for you when you move in (866-947-3368 see
            above hours).
          </p>
        </div>
        <div className="lead">
          <h4>Routers:</h4>
          <p>
            Most residents already own a router. If you wish to have your
            Internet service wireless, you will need to purchase / provide your
            own router. While any router will work on the network, over the
            years the following brands have proved to be more dependable than
            others.
          </p>
          <ul>
            <li>
              Netgear&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.netgear.com/home/wifi/routers/"
              >
                (https://www.netgear.com/home/wifi/routers/)
              </a>
            </li>
            <li>
              Linksys&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linksys.com/for-home/products/wifi-routers/"
              >
                (https://www.linksys.com/for-home/products/wifi-routers/)
              </a>
            </li>
            <li>
              Asus&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.asus.com/Networking-IoT-Servers/WiFi-Routers/All-series/"
              >
                (https://www.asus.com/Networking-IoT-Servers/WiFi-Routers/All-series/)
              </a>
            </li>
          </ul>
          <p>
            A more robust router will provide a better Internet experience, you
            really do get what you pay for.
          </p>
        </div>
        <div className="lead">
          <h4>Router Setup:</h4>
          <p>
            Once your account is created, and you have your router; please
            connect one end of the Internet cable (that came in the box with the
            router) to the Internet wall jack in your apartment, and the other
            end into the Internet (or WAN) port on the back of the router. Next,
            plug in the electrical cord to both the wall electrical outlet and
            the connection point on the back of the router. Next, please read
            the manufacturer's instructions that came with the router. After
            physically connecting the wires and turning the router on for the
            first time, the software will need to be configured. Most
            manufacturers have an App or website that you can visit to get the
            router configured properly for the first time. If you need
            assistance with software set-up, please contact the manufacture
            directly. If you accidentally push the reset button at any time
            during use, then you will have to perform the initial software set
            up again as described in the manufacturer's instructions.
          </p>
        </div>
        <div className="lead">
          <h4>Modems:</h4>
          <p>
            Modems will NOT work on the network. If the device / box says modem
            or gateway, it will not properly connect to the network. If you have
            old equipment from the Cable or DSL company please check with us,
            but again if it is a modem - it will not work.
          </p>
        </div>
        <div className="lead">
          <h4>Billing:</h4>
          <p>
            Once enrolled you will receive a digital invoice to the email
            address that you provided.
            <br />
            Invoices must be paid within 48 hours of generation or service may
            be interrupted until payment has been made.
            <br />
            Invoices will be generated on the enrollment day every month (if you
            enrolled on Jan 1st, then your invoices will sent to you on Jan 1,
            Feb 1, Mar 1, Apr 1, etc.) until you notify MDU Network Technologies
            that wish to cancel service. Your apartment staff does NOT notify
            us, that you have moved.
            <br />
            If the payment was not received within the 48-hour window, and the
            account was suspended, then future invoices dates may be moved to
            reflect the date the past due invoice was paid. Thus, future payment
            dates may no longer fall on the original enrollment date.
            <br />
            Payments to re-active service that are past due will be turned back
            on the following manner:
            <ul>
              <li>
                Within two hours (M, T, W, Th, F - 8:30 AM to 4:30 PM Eastern),
                or
              </li>
              <li>
                First thing the next business morning (Sat, Sun, after holidays)
              </li>
            </ul>
            To change service packages or to cancel service please contact
            866-947-3368 (M, T, W, Th, F - 8:30 AM to 4:30 PM Eastern).
          </p>
        </div>

        <div className="text-center btn_ctn">
          <button className="next-btn" onClick={handleForward}>
            Fill out form
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default Information;
