import React from 'react';
import { Navbar, Container } from 'react-bootstrap';

const Nav = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container fluid>
        <Navbar.Brand className="nav-title">
          <img
            alt="MDU Network Technologies"
            src="/logo_MDU.jpg"
            width="45"
            height="auto"
            className="d-inline-block align-top"
          />
          &nbsp; MDU<span style={{ color: '#009C00' }}> NET</span>ECH
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default Nav;
