import React from 'react';

interface Props {
  DownloadSpeed: number;
  UploadSpeed: number;
  available: boolean;
  bill_type: string;
  plan_description: string;
  plan_id: string;
  plan_name: string;
  plan_notes: string;
  price_base: number;
  price_tax_fee: number;
  prop_id: string;
  isDisabled?: boolean;
  onClick: () => void;
}

const Plan: React.FC<Props> = (props: Props) => {
  return (
    <div className="plan-item">
      <section
        className="text-center"
        style={{ padding: '10px', background: '#F7F7F7' }}
      >
        <h3>{props.plan_name}</h3>
      </section>
      <div className="plan-content">
        <section className="text-center">
          <h5 style={{ color: '#009C00' }}>
            ${(props.price_base + props.price_tax_fee).toFixed(2)}
          </h5>
          <button
            className="plan-btn mt-1"
            onClick={props.onClick}
            disabled={props.isDisabled}
          >
            Choose
          </button>
          <hr />
        </section>
        <section className="text-center">
          <div className=" mt-3">
            <span>
              <b>Download Speed:&nbsp;</b>
            </span>
            {props.DownloadSpeed} Mbps
          </div>
          <div className="mt-3">
            <span>
              <b>Upload Speed:&nbsp;</b>
            </span>
            {props.UploadSpeed} Mbps
          </div>
        </section>
        <section className="text-center mt-3">
          <i>Ideal for {props.plan_description}</i>
        </section>
      </div>
    </div>
  );
};

export default Plan;
