import React from 'react';
import Nav from '../components/Nav';
import { motion } from 'framer-motion';

const Success = () => {
  return (
    <React.Fragment>
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: '100%' }}
        exit={{ x: window.innerWidth }}
      >
        <div className="container p-4">
          <p className="display-5 text-center">
            Your request for service has been
            <br /> succesfully sent. You will be contacted soon.
          </p>
        </div>
      </motion.div>
    </React.Fragment>
  );
};

export default Success;
